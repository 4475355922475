.head-container{
    background-image: url('../../images/blog-cnc.jpg');
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: black;
}

.header-text{
    color: white;
    text-align: center;
    position: relative;
    top: 25%;
}

.header-text-style{
    font-size: 4em;
    font-family: 'Merriweather Sans', sans-serif;
}

@media screen and (max-width: 601px) {
    .header-text-style{
      font-size: 2em;
    }
    .head-container{
        height: 120vh;
        background-size: cover;
        background-position: center;

  }
}