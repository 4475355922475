.brand-font{
  font-family: 'Merriweather Sans', sans-serif;
}
.navbar-brand{
  width: 200px;
}

.button-white{
  border:1px solid white;
  color:white;
  background-color: transparent;
  width: 150px;
  height: 50px;
  margin-top:20px;
}
.button-white:hover{
  background-color:white;
  color:black;
}
.button-black{
  border:1px solid rgb(0, 0, 0);
  color:rgb(0, 0, 0);
  background-color: transparent;
  width: 150px;
  height: 50px;
  margin-top:20px;
}
.button-black:hover{
  background-color:rgb(0, 0, 0);
  color:rgb(255, 255, 255);
}
.text{
color:#000000;
}
.socials{
  justify-content: center;
  margin: 0 auto;
  align-items: center;
  display: flex;
}
.socials-copyright{
  justify-content: end;
  margin: 0 auto;
  align-items: end;
  display: flex;
}
.socials-logo{
  justify-content: start;
  margin: 0 auto;
  display: flex;
  max-width: 250px;
}
.footer{
  border-top: 1px solid #004444;
  border-radius: 20px;
  
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #d1d1d1 !important;
}
.CircularProgressbar .CircularProgressbar-text {
 fill:  rgb(214, 214, 214) !important;
}
.service-background {
  background-color: #004444;
  backdrop-filter: blur(100px); /* Adjust the blur amount as needed */
  background-image: url('./components/images/dark-green-blur-background-vector.png');
  background-size: cover;



}
.metalcraft{
  opacity: 0.7;
  border-radius: 20px;
  width: 100%;
}
.card-img-top{
  width: auto;
  height: 200px;
}