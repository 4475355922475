.statistic-container{
    background-image: url('../../images/inovattion.png');
    height: 80vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
@media only screen and (max-width: 600px) {

.statistic-container{
    height: 180vh;
    background-attachment:scroll;
    background-position: center;

}
.statistic-title{
    font-size: 3em;
}
}